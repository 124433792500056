import React from "react";
import GetInTouch from "../components/GetInTouch";
import Layout from "../components/Layout";
import MainBanner from "../components/MainBanner";
import Revolutionary from "../components/Revolutionary";
import EngageRealtime from "../components/EngageRealtime";
import Testamonials from "../components/Testamonials";
import OverlayExample from "../components/OverlayExample";
import InteractiveDemo from "../components/InteractiveDemo";
import Opportunities from "../components/Opportunities";

import "../styles/index.less";

const IndexPage: React.FC = (props) => {
  return (
    <Layout>
      <MainBanner></MainBanner>
      <div className="main-container">
        <Revolutionary></Revolutionary>
        <EngageRealtime></EngageRealtime>
        <OverlayExample></OverlayExample>
        <InteractiveDemo></InteractiveDemo>
        <Testamonials></Testamonials>
        <Opportunities></Opportunities>
        <GetInTouch></GetInTouch>
      </div>
    </Layout>
  );
};

export default IndexPage;
