import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Typography } from "antd";

import "./index.less";

const { Title } = Typography;

const InteractiveDemo: React.FC = (props) => {
  const [selectedLink, setSelectedLink] = useState("nike");

  return (
    <section
      id="interactive-demo"
      className="section interactive-demo with-margin"
    >
      <div className="video-placeholder-container">
        {selectedLink == "nike" ? (
          <a href="https://prime.sourcesync.io/155" target="_blank">
            <StaticImage
              objectFit="contain"
              loading="lazy"
              placeholder="none"
              src={"../../images/Nike.png"}
              alt="Nike Commercial"
              className="video-placeholder"
            ></StaticImage>
          </a>
        ) : (
          <a href="https://prime.sourcesync.io/159" target="_blank">
            <StaticImage
              objectFit="contain"
              loading="lazy"
              placeholder="none"
              src={"../../images/Pitbull.png"}
              alt="Pitbull Concert"
              className="video-placeholder"
            ></StaticImage>
          </a>
        )}
      </div>
      <div className="menu-bar">
        <Title className="title">Interactive Demo</Title>
        <div className="links">
          <a
            onClick={() => setSelectedLink("nike")}
            className={"link" + (selectedLink == "nike" ? " selected" : "")}
          >
            Nike Promo Video
          </a>
          <a
            onClick={() => setSelectedLink("pitbull")}
            className={"link" + (selectedLink == "pitbull" ? " selected" : "")}
          >
            Pitbull Live Concert
          </a>
        </div>
      </div>
    </section>
  );
};

export default InteractiveDemo;
