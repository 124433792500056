import React from "react";
import { Typography, Carousel } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./index.less";

const { Title, Text } = Typography;

const Testamonials: React.FC = () => {
  return (
    <section id="testamonials" className="section testamonials with-margin">
      <Carousel
        dots={{
          className: "dot",
        }}
        autoplay
        infinite
        swipe
        arrows
        prevArrow={
          <StaticImage
            placeholder="none"
            src="../../images/arrow.png"
            alt="left-arrow"
            imgClassName="left-arrow"
          ></StaticImage>
        }
        nextArrow={
          <StaticImage
            placeholder="none"
            src="../../images/arrow.png"
            alt="right-arrow"
            className="right-arrow"
          ></StaticImage>
        }
      >
        <div className="slide">
          <Title className="title">
            "PWDigital's tech is the future. You might as well get used to it
            now, because it's a tool thats very very valuable."
          </Title>
          <div className="texts">
            <Text className="text light">Pitbull</Text>
            <Text className="text">
              Grammy award-winning rapper, songwriter, and record producer.
            </Text>
          </div>
        </div>
        <div className="slide">
          <Title className="title">
            "This is the ad industry’s next big disruption. Without a doubt"
          </Title>
          <div className="texts">
            <Text className="text light">Nike</Text>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Testamonials;
