import React from "react";
import { Row, Col, Typography, Space } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OverlayAnimation from "../../assets/animations/animation-overlays.mp4";
import { Fade } from "react-awesome-reveal";

import "./index.less";

const { Title, Text } = Typography;

const OverlayExample: React.FC = (props) => {
  return (
    <section
      id="overlay-examples"
      className="section overlay-examples with-margin"
    >
      <Row>
        <Col xs={24} md={12} lg={12} xl={12} className="image-col">
          <video className="overlays-animation" autoPlay loop muted playsInline>
            <source src={OverlayAnimation} type="video/mp4" />
          </video>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} className="text-col">
          <Space align="center" className="h-100">
            <Space align="start" direction="vertical" className="h-100">
              <Fade triggerOnce cascade direction="up">
                <Title className="title">
                  Fully Customizable<br></br>Overlays
                </Title>
                <Text className="text">
                  PWDigital's patented overlay is versatile and can include
                  various form of media including video, photo, text, and sound;
                  giving your audience a more immersive and engaging experience
                </Text>
                <AnchorLink
                  className="link custom-link-with-arrow"
                  href="#interactive-demo"
                  offset={() => 48}
                >
                  <span className="underline">
                    Watch a live interactive demo
                  </span>
                  <StaticImage
                    loading="lazy"
                    placeholder="none"
                    src="../../images/arrow.png"
                    alt="right-arrow"
                    className="right-link-arrow right-arrow"
                  ></StaticImage>
                </AnchorLink>
              </Fade>
            </Space>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default OverlayExample;
