import React from "react";
import { Row, Col, Typography, Space } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import MobileAnimation from "../../assets/animations/mobile-example.mp4";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Fade } from "react-awesome-reveal";

import "./index.less";

const { Title, Text } = Typography;

const Revolutionary: React.FC = (props) => {
  return (
    <section id="revolutionary" className="section revolutionary with-margin">
      <Row>
        <Col xs={24} md={12} lg={12} xl={12} className="video-col">
          <video className="video" autoPlay loop muted playsInline>
            <source src={MobileAnimation} type="video/mp4" />
          </video>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} className="text-col">
          <Space align="center" className="h-100">
            <Space align="start" direction="vertical" className="h-100">
              <Fade triggerOnce cascade direction="up">
                <Title className="title">
                  Revolutionary patented<br></br>Technology
                </Title>
                <Text className="text">
                  PWDigital's platform is a personalized ad delivery system that
                  creates engageable moments within videos for users. This can
                  be simply integrated on any video player; mobile, desktop or
                  tablet.
                </Text>
                <AnchorLink
                  className="link custom-link-with-arrow"
                  href="#engage-real-time"
                  offset={() => 48}
                >
                  <span className="underline">See how the platform works</span>
                  <StaticImage
                    loading="lazy"
                    placeholder="none"
                    src="../../images/arrow.png"
                    alt="right-arrow"
                    className="right-link-arrow right-arrow"
                  ></StaticImage>
                </AnchorLink>
              </Fade>
            </Space>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default Revolutionary;
