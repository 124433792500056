import React from "react";
import { Typography, Button, Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StaticImage } from "gatsby-plugin-image";

import "./index.less";

const { Title, Text } = Typography;

const MainBanner: React.FC = (props) => {
  return (
    <section className="section main-banner">
      <div className="banner">
        <div className="banner-overlay">
          <Fade direction="up" triggerOnce cascade>
            <Title className="title">
              The Future of Ad Tech<br></br>is HERE.
            </Title>
            <Text className="subtitle">Cut through the noise and connect</Text>
            <Button className="tell-me-more-btn">
              <AnchorLink href="#revolutionary" offset={() => 48}>
                Tell Me More
              </AnchorLink>
            </Button>
          </Fade>
        </div>
      </div>
      <div className="sub-banner">
        <Row
          className="sub-banner-row"
          gutter={{ xs: 16, sm: 16, md: 5, lg: 32 }}
        >
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce>
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgClassName="brand-img"
                src="../../images/brands/Motortrend.png"
                alt="Motortrend"
              ></StaticImage>
            </Fade>
          </Col>
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce delay={200}>
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgClassName="brand-img p10"
                src="../../images/brands/FoxMovies.png"
                alt="FoxMovies"
              ></StaticImage>
            </Fade>
          </Col>
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce delay={300}>
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgClassName="brand-img p5"
                src="../../images/brands/Golf.png"
                alt="Golf"
              ></StaticImage>
            </Fade>
          </Col>
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce delay={400}>
              <StaticImage
                placeholder="none"
                loading="lazy"
                imgClassName="brand-img p15"
                src="../../images/brands/Nike.png"
                alt="Nike"
              ></StaticImage>
            </Fade>
          </Col>
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce delay={500}>
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgClassName="brand-img p20"
                src="../../images/brands/HipHop.png"
                alt="HipHop"
              ></StaticImage>
            </Fade>
          </Col>
          <Col xs={8} md={4} lg={4} xl={4}>
            <Fade direction="left" triggerOnce delay={600}>
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgClassName="brand-img"
                src="../../images/brands/NBC.png"
                alt="NBC"
              ></StaticImage>
            </Fade>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MainBanner;
