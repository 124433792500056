import React from "react";
import { Typography, Button } from "antd";

import "./index.less";

const { Title, Text } = Typography;

const GetInTouch: React.FC = () => {
  return (
    <section id="get-in-touch" className="section get-in-touch with-margin">
      <Title className="title" level={1}>
        Get in Touch
      </Title>
      <Text className="text">
        Join all the others already using our patented technology and start
        creating that deeper connection to you audience today.
      </Text>
      <Button className="contact-btn">
        <a target="_blank" href="mailto:sales@pwdigital.io">
          Contact Us
        </a>
      </Button>
      <Text className="text">sales@pwdigital.io</Text>
    </section>
  );
};

export default GetInTouch;
