import React from "react";
import { Typography, Button, Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StaticImage } from "gatsby-plugin-image";

import "./index.less";

const { Title, Text } = Typography;

const Opportunities: React.FC = (props) => {
  return (
    <section className="section opportunities-banner">
      <div className="banner">
        <div className="banner-overlay">
          <Fade direction="up" triggerOnce cascade>
            <Title className="main-title">
              Opportunities
            </Title>
            <Text className="main-subtitle">How Can I Benefit?</Text>
          </Fade>
          <Fade direction="up" triggerOnce cascade>
            <Title className="title">
              Platforms
            </Title>
            <Text className="subtitle">Give your creators an option to open up their potential revenue streams and profit.</Text>
            <Title className="title">Brands</Title>
            <Text className="subtitle">Remove resistance to purchase by offering a simple click and buy solution.</Text>
            <Title className="title">Content Creators</Title>
            <Text className="subtitle">Increase engagement by adding a new layer of info and interaction.</Text>
            <Title className="title">Influencers</Title>
            <Text className="subtitle">Give your sponsors a new channel for more exposure.</Text>
          </Fade>
        </div>
      </div>
    </section>);
};

export default Opportunities;
