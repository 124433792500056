import React from "react";
import { Row, Col, Typography, Space } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import NikeAnimation from "../../assets/animations/animation-overlays-nike.mp4";
import TimelineAnimation from "../../assets/animations/timeline.mp4";
import { Fade } from "react-awesome-reveal";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./index.less";

const { Title, Text } = Typography;

const EngageRealtime: React.FC = (props) => {
  return (
    <section
      id="engage-real-time"
      className="section engage-real-time with-margin"
    >
      <Row>
        <Col xs={24} md={12} lg={12} xl={12} className="text-col">
          <Space align="center" className="h-100">
            <Space align="start" direction="vertical" className="h-100">
              <Fade triggerOnce cascade direction="up">
                <Title className="title">Engage in real time</Title>
                <Text className="text">
                  This additional layer allows for the creation of a whole new
                  level of reach and interactivity with your audience. Add value
                  to your audience while never disturbing the user experience.
                </Text>
                <AnchorLink
                  className="link custom-link-with-arrow"
                  href="#overlay-examples"
                  offset={() => 48}
                >
                  <span className="underline">
                    See the technology in action
                  </span>
                  <StaticImage
                    loading="lazy"
                    placeholder="none"
                    src="../../images/arrow.png"
                    alt="right-arrow"
                    className="right-link-arrow right-arrow"
                  ></StaticImage>
                </AnchorLink>
              </Fade>
            </Space>
          </Space>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} className="image-col">
          <video className="nike-animation" autoPlay loop muted playsInline>
            <source src={NikeAnimation} type="video/mp4" />
          </video>
        </Col>
      </Row>
      <video className="timeline-animation" autoPlay loop muted playsInline>
        <source src={TimelineAnimation} type="video/mp4" />
      </video>
    </section>
  );
};

export default EngageRealtime;
